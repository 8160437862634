.prodect-area{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    width: 100%;
    margin: 200px 0px 100px 0;
    
}
.prodect-image{
    width: 200px;
}

.prodect-image img{
    width: 100%;
    border-radius: 30px;
}

.prodect-detail{
    width: 400px;
    margin-left: 45px;
    margin-top: -22PX;
    line-height: 35px;
}
.prodect-detail h1{
    font-size: 62.7px;
    top: 0;
    color: white;
}
.prodect-detail p{
    font-size: 20px;
    color: white;
}

.btn-sub{
    width: 310px;
    font-size: 21px;
    padding: 20px 60px;
    border-radius: 50px;
    margin-top: 30px;
}

.btn-sub:hover {
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 1);
    /* -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57); */
    box-shadow: 0px 0px 20px -5px rgba(255, 255, 255, 1);
    transition: all 0.4s ease 0s;
    }


.prodect-contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    margin-bottom: 150px;
    
}
.page-st{
    width: 700px;
    font-size: 25px;
}
.page-input{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0 20px;
}

.page-input label{
    font-size: 25px;
}

.page-input input{
    width: 300px;
    margin-top: 10px;
    font-size: 25px;
    padding: 20px 60px;
    border-radius: 50px;
    background: transparent;
    color:white;
    text-align: start;
}
.page-form{
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}