.header-29__box{
    background-color: #303030;
    background-image: url(https://ik.imagekit.io/neuwx10vxnmeo/enginify/images/video/intro_VnPLCnKoWf.jpg?updatedAt=1718015735705);
    background-repeat: no-repeat;
    background-size: 100% 600px;
    height: 600px;
    
}
 
.heading {
    font-size: 85px;
    color: white;
    text-align: center;

    line-height: 1.1;
    margin-bottom: 0.2em;
    margin-top: 250px;
    font-weight: 500;
}

.page-component__wrapper{
    z-index: 16;padding-bottom:20px;
}

.header-29__text_box{
    /* padding: 17 0px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Card{
    width: 20px;
    border: 1px solid black;
}

.home-join{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 600px;
    background-color: #076b00;
}
.join-contaner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.join-heading{
    width: 900px;
    text-align: center;
    font-size: 30px;
    color: white;
}
.join-st{
    text-align: center;
    font-size: 18px;
    color: white;
}

.join-contaner button{
    margin-top:20px ;
    width: 200px;
    padding: 18px;
    border-radius: 40px;
    font-size: 18px;
}
.form__input_1 input{
    width: 350px;
    height: 45px;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 35px;
    background: transparent;
    margin-right:20px;
    margin-top: 10px;
    color: white;
}
.form__input{
    margin: 20px;
}
.form__textarea{
    margin: 20px;
}

.header-29-form{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.form__input input{
    width: 750px;
    height: 45px;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 35px;
    background: transparent;
    color: white;
    margin-top: 10px;
}
.form__input select{
    width: 750px;
    height: 55px;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 35px;
    background: transparent;
    color:white;
    position: relative;
    z-index: 1;
    margin-top: 10px;
}
.form__input select option{
    width: 750px;
    height: 62px;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 35px;
    background: transparent;
    color:rgb(6, 6, 6);

}
.form__textarea textarea{
    max-width: 750px;
    min-width: 750px;
    height: 45px;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 35px;
    background: transparent;
    color:white;
    margin-top: 10px;
}
.form__input_1 label{
    margin-top: 20px;
    color: white;
}
.form__input label{
    color: white;
}

.form__textarea label{
    color: white;
}

.form__inputs-1{
    display: flex;
    flex-direction:row ; 
    justify-content: center;
    align-items: center;
}
.form__button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button{
    width:200px;
    padding: 15px;
    border-radius: 30px;
    font-size: 25px;
}



.saas-body{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* align-items: center; */
    padding: 0 20px;
}

.saas-card{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    height: auto;
    width: 30%;
    border-radius: 20px;
    color: white;

}
.saas-card img{
    border-radius: 25px;
    width: 100%;
    height: 320px;
    
}
.saas-h1{
    font-size: 12px;
}
.saas-card-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 170px;
    padding: 0 20px;

}
.saas-header{
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.saas-header h1{
    font-size: 20px;
    color: white;
    .large-text {
            font-size: 18px;
        }
    
    .small-text {
        color: darkgray;
        font-size: 12px;
    }
}
.saas-header p{
    font-size: 20px;
    color: white;
}

.company-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.company-img1 img{
    width: 194px;
    padding: 20px 20px;
}
.company-img2 img{
    width: 194px;
    padding: 20px 20px;
}

.company-img2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.join-heading{
    line-height: 30px;
    
}

.Supercharge{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 100px 0px;
}

.Supercharge-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Supercharge-cotaner{
    width: 600px;
}
.Supercharge-form{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Supercharge-fname{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    margin-top: 20px;
}
.Supercharge-fname input{
    width: 240px;
    height: 45px;
    font-size: 20px;
    padding: 5px 10px;
    border-radius: 35px;
    background: transparent;
    margin-right: 20px;
    color: white;
}

.Supercharge-fname label{
    font-size: 20px;
}

.Supercharge-btn button{
    width: 281px;
    font-size: 21px;
    padding: 16px 36px;
    border-radius: 29px;
    margin-top: 47px;
}

.Supercharge-btn button:hover {
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 1);
    /* -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57); */
    box-shadow: 0px 0px 20px -5px rgba(255, 255, 255, 1);
    transition: all 0.4s ease 0s;
    }

.feature__button_box{
    margin-bottom: 100px;
}

.feature__button_box a{
    font-size: 22px;
    font-weight: 700;
    color: white;
}










.feature__button_box a:hover {
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 1); 
    transition: all 0.4s ease 0s;
    }











