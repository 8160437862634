nav{
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0;
}
.container1{
    background-color: #303030;
    color: white;
    height: 80px;
}
.nav-02__box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:15px 30px;
}

.nav-02__links button{
    background-color: white;
    width: 200px;
    background: transparent;
    border: 2px solid white;
    padding: 10px 20px;
    font-size: 20px;
    color: white;
    border-radius: 26px;
}