.container-footer{
    background-color: #303030;
    color: white;
   
    width: 100%;
}
.footer-02__wrapper{
    padding: 0 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.social-buttons__icon{
    padding: 0 5px;
    width: 20px;
}
.social-buttons__list{
    padding: 0 20px;
    width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center ;
}
ul{
    list-style-type: none;
}

.content-text a{
    font-size: 20px;
    color: white;
    text-decoration: none;
    margin: 0 5px;

}