.card-contaner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
}
.card1{
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: self-start;

    border: 1px solid white;
    width: 321px;
    height: 246px;

    border-radius:10px;
    background-color: #161616
    
}
.card1 a{
    color: white;
    text-decoration: none;
    padding: 0 20px;
}


.card-bo{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.card-bo img{
    width: 30px;
    border-radius: 10px;
    filter: invert(1);

}
.star-img{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.star-img img{
    width: 20px;
    padding: 6px;
    filter: invert(0);
}





.card1:hover {
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 1);
    /* -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57); */
    box-shadow: 0px 0px 20px -5px rgba(255, 255, 255, 1);
    transition: all 0.4s ease 0s;
    }
  
 
